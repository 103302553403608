<template>
  <div class="login">
    <p class="backbox">
      <span class="iconfont icon-fanhui-copy back" @click="goBack"></span>
    </p>
    <div class="title">
      <!-- <img src="@/assets/images/login-bk.png" alt="" /> -->
      <img :src="valueWorth" alt="" />
      <!-- <p class='shop-name'>货运公司商城</p> -->
    </div>
    <div class="info phone">
      <div class="txt">手机号</div>
      <div class="login-box-input">
        <input
          type="tel"
          v-model="phone"
          :maxlength="11"
          placeholder="请输入手机号"
          class="login-input"
          @input="handleInput"
        />
      </div>
    </div>
    <div class="info yanzheng">
      <div class="txt">验证码</div>
      <div class="yanzheng-flex">
        <div class="login-box-input yanzheng-box">
          <input
            type="tel"
            v-model="yanzheng"
            :maxlength="6"
            placeholder="请输入验证码"
            class="login-input"
          />
        </div>
        <van-button
          v-if="showtime === null"
          @click.prevent="getCode($event)"
          :disabled="disabled"
          class="getCode"
          v-preventReClick="3000"
          >获取验证码</van-button
        >
        <i v-else class="time">{{ showtime }}</i>
      </div>
    </div>
    <van-button
      class="btn"
      @click.native="loginIn"
      v-if="showlogin"
      v-preventReClick="3000"
      >登录</van-button
    >
    <van-button class="btn btn-no" disabled v-else v-preventReClick="3000"
      >登 录 中...</van-button
    >
    <!-- <div class="sign">
        <van-checkbox v-model="checked" checked-color="#C83F3F" class='checkbox-big' icon-size=".42rem"></van-checkbox>
        <div class="agree">
          登录即代表同意《
          <a @click="showAgree">货运公司商城用户协议</a>》及《
          <a @click="showprivacypolicy">货运公司商城隐私政策</a>》
        </div>
      </div> -->
    <div class="isthree">
      <!-- <div style="text-align: center; width: 100vw">第三方登录</div>
      <div class="isthreeLogin">
        <div v-if="deviceEnvir == 'IOS'">
          <img
            @click="appleLogin()"
            src="@/assets/images/appleLogin.png"
            style="width: 0.47rem; height: 0.47rem"
          />
        </div>
        <div>
          <img
            @click="goWx()"
            src="@/assets/images/wxLogin.png"
            style="width: 0.57rem; height: 0.57rem"
          />
        </div>
      </div> -->
      <label>@{{ $store.state.user.version }}</label>
    </div>
    <!-- <div class="extra">
      <div class="register" @click="goRegister">注册会员</div>
      <div class="unable">无法登录？</div>
    </div>
    <div class="more-text">第三方登录</div>
    <div class="more-login">
      <img src="@/assets/images/apple.png" alt="" class="apple icon-login" />
      <img src="@/assets/images/weixin.png" alt="" class="weixin icon-login" />
    </div> -->

    <!-- <div class="more-login">
      <img src="@/assets/images/zh_logo.png" alt="" class="weixin icon-login" @click="goBank"/>
      招行登录
    </div> -->
  </div>
</template>


<script>
import Btn from "@/components/common/btn";
import Heador from "./loginhead";
import { parse } from "path";
import { setToken } from "@/utils/auth";
import { log } from 'util';

// console.log("用户IP地址：" + APIkey["IP_Address"]);

export default {
  components: {
    Btn,
    Heador,
  },
  data() {
    return {
      showtime: null,
      phone: "",
      yanzheng: "",
      recommd: "", // 推荐码
      showlogin: true,
      deviceEnvir: this.Base.deviceEnvir(),
      checked: false,
      wx: this.is_weixn(),
      isHaveWechat: true,
      disabled: true,
      valueWorth: "",
    };
  },
  created() {
    // let logobackgroundUrl = localStorage.getItem("logobackgroundUrl");
    // if (logobackgroundUrl) {
    //   this.valueWorth = localStorage.getItem("logobackgroundUrl");
    // }
    // if (!this.valueWorth) {
    //   this.getLogoUrl();
    // }
    this.getLogoUrl();
    window.appleLoginCallback = this.appleLoginCallback;
    window.wxLoginCallback = this.wxLoginCallback;

    window.checkWechatCallback = this.checkWechatCallback;
    this.checkWechat();
    this.detectionUpdate();

    // 如果有人传递扫码进入登录页面，推荐码直接赋值显示
    const referralCode = this.$route.query.referralCode;
    if (referralCode) {
      // 不为空的推荐码时
      this.recommd = referralCode;
    }

    // let wxClient = this.is_weixn();
    // if (wxClient) {
    //   if (!sessionStorage.openId) {
    //     let appid = localStorage.getItem("appid");

    //     // let url = location.href.split("#/")[0] + "/#/getOpenIdIndex";
    //     let url = `${this.$globalConfig.redirectuUrl}/wx/redirect`;
    //     // console.log(encodeURIComponent(url));
    //     url = encodeURIComponent(url);
    //     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
    //   }
    // }

    if (localStorage.storeId) {
      this.getStoreName();
    }
  },
  deactivated() {
    this.showlogin = true; // 显示登录按钮
    clearTimeout(this.timeCounter); // 清除定时器
    this.showtime = null;
    this.phone = "";
    this.disabled = true;
  },
  activated() {
    // isUseCache为false时才重新刷新获取数据
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      this.value = "";
      this.yanzheng = "";
      this.recommd = "";
    }
    // 通过这个控制刷新
    this.$route.meta.isUseCache = false;
  },
  methods: {
    getLogoUrl() {
      this.$api.login
        .getConfigLogoUrl({
          k: "logoUrl",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
            this.valueWorth = res.data.valueWorth;
            localStorage.setItem("logobackgroundUrl", this.valueWorth);
            // console.log(this.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    // 显示协议
    showAgree() {
      this.$router.push("/agree");
    },

    //检测是否更新 清除缓存
    detectionUpdate() {
      this.$api.login
        .getH5Version(this.$store.state.user.version)
        .then((res) => {
          console.log(res);
          if (!res.data) {
            // this.$toast('清除缓存')
            this.clearStorage();
          }
        });
    },

    // 清除缓存
    clearStorage() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if (isAndroid === true) {
        window.android.invokeAndroid("cmd003");
        // this.$toast('安卓缓存已清除')
        return "Android";
      } else if (isIOS === true) {
        // Iphone清除缓存
        var paragramStr = ["cmd003"];
        window.webkit.messageHandlers.clearCache.postMessage(paragramStr);
        // this.$toast('ios缓存已清除')

        return "IOS";
      } else {
        return "PC";
      }
    },

    // 显示协议
    showprivacypolicy() {
      this.$router.push("/privacypolicy");
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      this.timesText = times;
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      this.count = 1;
      if (this.count === 1) {
        this.timeCounter = setTimeout(this.countDownStart, 0);
      } else {
        this.timeCounter = setTimeout(this.countDownStart, interval);
      }
    },

    // 定时器中的方法
    countDownStart() {
      if (this.timeCounter === null) {
        return false;
      }
      const interval = 1000;

      this.count += 1;
      // console.log(this.countDownText);
      this.countDownText(this.timesText - this.count + 1);
      if (this.count > this.timesText) {
        this.showlogin = true; // 显示登录按钮
        clearTimeout(this.timeCounter); // 清除定时器
        this.showtime = null;
      } else {
        this.timeCounter = setTimeout(this.countDownStart, interval);
      }
    },
    // 获取验证码
    getCode(event) {
      // 点击太过频繁
      if (event.target.disabled) {
        return false;
      }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        this.$api.login
          .yanCodeAddress({
            phone: this.phone,
            _s: 5,
          })
          .then((res) => {
            //console.log("获取验证码", res);
            if (res.errno === 200) {
              // 调用倒计时
              this.countDown(60);
              // 测试验证码
              this.yanzheng = res.data.code;
            }
          });
      }
    },
    goBack() {
      // let url = decodeURIComponent(this.$route.query.redirect);
      // this.$router.replace({ path: url });
      this.$router.go(-1);
    },
    //小店的话获取小店id
    getStoreName() {
      this.$api.home.getStoreInfo(localStorage.storeId).then((res) => {
        if (res.errno === 200) {
          document.title = res.data.storeName;
        }
      });
    },

    // 确定登录 优化版本
    loginIn() {
      // if (!this.checked) {
      //   this.$toast("请阅读并同意货运公司商城用户协议和隐私政策");
      //   return;
      // }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      // 电话号码正确就登录
      if (!reg.test(this.phone)) {
        this.$toast("电话号码有误");
        return false;
      } else {
        this.showlogin = false; // 点击登录之后就变灰色
        //console.log(this.$api.login);
        this.$api.login
          .loginAddress({
            phone: this.phone,
            mobileCode: this.yanzheng,
            referralCode: this.recommd,
            isWomen: this.$route.query.isWomen || "",
          })
          .then((res) => {
            // 登录成功
            if (res.errmsg === "成功") {
              //console.log(666666666666);
              this.$toast({
                message: "登录成功",
                duration: 1500,
                onClose: () => {
                  //console.log(res);
                  setToken(res.data.accessToken);
                  // 把token存入本地
                  localStorage.setItem("token", res.data.accessToken);
                  localStorage.setItem("customerLevel", res.data.level);
                  localStorage.setItem("customerId", res.data.id);
                  localStorage.setItem(
                    "personalNumber",
                    res.data.birthday
                    // res.returnValue.loginCustomer.personalNumber
                  );
                  localStorage.setItem(
                    "customerName",
                    // res.returnValue.loginCustomer.name
                    res.data.nickname
                  );
                  localStorage.setItem(
                    "mobile",
                    // res.returnValue.loginCustomer.mobile
                    res.data.phone
                  );
                  localStorage.setItem("isNew", res.data.loginType);
                  // res.returnValue.isNew);
                  localStorage.setItem(
                    "isWomenFestival",
                    // res.returnValue.isWomenFestival
                    res.data.status
                  );
                  // 跳转页面
                  try {
                    if (this.$route.query.redirect) {
                      if(this.countOccurrences(decodeURIComponent(this.$route.query.redirect),'%')>=1){this.$router.replace({ path: "/my" }); return}
                      if(decodeURIComponent(this.$route.query.redirect).indexOf('login') != '-1'){this.$router.replace({ path: "/my" }); return}
                      let url = decodeURIComponent(this.$route.query.redirect);
                      this.$router.replace({ path: url });
                    } else {
                      this.$router.replace({ path: "/my" });
                    }
                  } catch (err) {
                    this.$router.replace({ path: "/my" });
                  }
                },
              });
            } else {
              this.showlogin = true;
              this.$toast(res.errmsg);
            }
          });
      }
    },
    //判断路由正确
    countOccurrences(str, char) {
      // 使用正则表达式匹配所有的斜杠，并返回匹配到的数组
      var matches = str.match(new RegExp(char, 'g'));
      // 返回斜杠的数量，如果没有匹配到则返回 0
      return matches ? matches.length : 0;
    },
    // 调用原生获取设备号
    getDeviceId() {
      if (this.deviceEnvir === "IOS") {
      } else if (this.deviceEnvir === "ANDROID") {
        let params = JSON.stringify({ cmd: "013" });
        let deviceId = window.android.invokeAndroid(params);
        this.addAppDevice(deviceId);
      }
    },
    addAppDevice(deviceId) {
      this.$server.apkApi
        .addAppDeviceAddress({
          accessToken: localStorage.getItem("token"),
          bizType: 0,
          bizId: localStorage.getItem("customerId"),
          deviceId,
        })
        .then((res) => {});
    },

    //微信登录

    goWx() {
      // if (!this.checked) {
      //   this.$toast("请阅读并同意货运公司商城用户协议和隐私政策");
      //   return;
      // }

      //   this.$toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: this.deviceEnvir +'111',
      // });
      // return

      // this.$api.home.getCode().then((res) => {
      //   console.log(res);
      // });
      // return;
      let wxClient = this.is_weixn();
      if (wxClient) {
        // console.log(777);
        // sessionStorage.openId = 123;
        let arr = {
          openId: sessionStorage.openId,
        };

        this.$api.login.wxgo(arr).then((res) => {
          // console.log(res);
          // console.log(777);
          // sessionStorage.openId = 123;

          if (!res.data.accessToken) {
            this.$toast("您尚未绑定微信");

            setTimeout(this.goTowx, 2000);
          } else {
            this.$toast({
              message: "登录成功",
              duration: 1500,
              onClose: () => {
                //console.log(res);
                setToken(res.data.accessToken);
                // 把token存入本地
                localStorage.setItem("token", res.data.accessToken);
                localStorage.setItem("customerLevel", res.data.level);
                localStorage.setItem("customerId", res.data.id);
                localStorage.setItem(
                  "personalNumber",
                  res.data.birthday
                  // res.returnValue.loginCustomer.personalNumber
                );
                localStorage.setItem(
                  "customerName",
                  // res.returnValue.loginCustomer.name
                  res.data.nickname
                );
                localStorage.setItem(
                  "mobile",
                  // res.returnValue.loginCustomer.mobile
                  res.data.phone
                );
                localStorage.setItem("isNew", res.data.loginType);
                // res.returnValue.isNew);
                localStorage.setItem(
                  "isWomenFestival",
                  // res.returnValue.isWomenFestival
                  res.data.status
                );
                // 跳转页面
                try {
                  //console.log(this.$route.query);
                  if (this.$route.query.redirect) {
                    let url = decodeURIComponent(this.$route.query.redirect);
                    this.$router.replace({ path: url });
                  } else {
                    this.$router.replace({ path: "/my" });
                  }
                } catch (err) {
                  this.$router.replace({ path: "/my" });
                }
              },
            });
          }
        });
        // let appid = window.g.appid;
        // let url = window.g.wxUrl;
        // // let url = "http://dhmall-app-v2.keplerlab.cn/?#/login";
        // console.log(encodeURIComponent(url));
        // url = encodeURIComponent(url);
        // return;
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
        // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`;
        // 微信环境内
        // console.log("用户IP地址：" + APIkey["IP_Address"]);

        // let arr = {
        //   ip: returnCitySN["cip"],
        //   raw: "",
        //   loginType: "",
        // };
        // console.log(arr);
        // return;
        // this.$api.login.wogo();
      } else {
        this.appWxlogin();
      }
    },

    goTowx() {
      this.$router.push({
        path: "/wxlogin",
        query: {
          redirect: this.$route.query.redirect,
        },
      });
    },

    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    appWxlogin() {
      // if (this.deviceEnvir === "IOS") {
      // let apk = this.$store.state.apkVersion;
      // var goWxApkVersion = this.toNum(apk);
      // if (goWxApkVersion < this.toNum("3.6")) {
      //   Toast("请您先升级到最新版本");
      //   return;
      // }
      // }
      // this.$toast.loading({
      //   duration: 300000, // 持续展示 toast
      //   forbidClick: true,
      //   message: "登录中1",
      // });
      // this.$server
      //   .wxLoginGetAppIDAddress({
      //     // 获取微信appid
      //     appName: "货运公司商城", //app的名字
      //     appVersion: this.$store.state.apkVersion,
      //   })
      //   .then((res) => {
      //     this.$toast.clear();
      //     if (res.code === 100) {
      //       this.appid = res.returnValue.appId;
      //       this.state = res.returnValue.state;
      let appid = localStorage.getItem("appid");

      let paramsObj = {
        appid: appid,
        state: 0,
      };

      switch (this.deviceEnvir) {
        case "IOS":
          try {
            window.webkit.messageHandlers.wxLogin.postMessage(paramsObj);
            // this.$toast.loading({
            //   duration: 100000, // 持续展示 toast
            //   forbidClick: true,
            //   message: JSON.stringify("苹果"),
            // });
          } catch (error) {
            this.$toast("暂不支持登录");
          }
          break;
        case "ANDROID":
          // this.$toast.loading({
          //   duration: 1000, // 持续展示 toast
          //   forbidClick: true,
          //   message: JSON.stringify(6666),
          // });
          paramsObj = JSON.stringify(paramsObj);

          try {
            window.revisionInterface.wxLogin(paramsObj);
            // this.$toast("支持登录");
          } catch (error) {
            this.$toast("暂不支持登录");
          }

          // window.revisionInterface.wxLogin(paramsObj);

          // this.$toast.loading({
          //   duration: 2000, // 持续展示 toast
          //   forbidClick: true,
          //   message: JSON.stringify(8888),
          // });
          break;
      }
      // }
      // });
    },

    handleInput() {
      // console.log("变色");
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },

    // 壳子回调
    //苹果identityToken回传JS
    appleLogin() {
      // if (!this.checked) {
      //   this.$toast("请阅读并同意货运公司商城用户协议和隐私政策");
      //   return;
      // }

      try {
        window.webkit.messageHandlers.appleLogin.postMessage("1");
      } catch (error) {
        this.$toast("暂不支持登录");
      }
    },

    appleLoginCallback(params) {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
      });
      let paramsObj = JSON.parse(params);
      let identityToken = paramsObj.identityToken;
      this.$api.login
        .appleLogin({
          // 苹果登录
          identityToken: identityToken,
        })
        .then((res) => {
          // this.$toast.loading({
          //   duration: 500000, // 持续展示 toast
          //   forbidClick: true,
          //   message: JSON.stringify(res.data),
          // });
          if (!res.data) {
            this.$toast.clear();

            this.$toast(res.errmsg);
            return;
          }

          if (!res.data.accessToken) {
            this.$toast.clear();
            // this.$toast("您尚未绑定手机");
            // this.$toast.loading({
            //   duration: 500000, // 持续展示 toast
            //   forbidClick: true,
            //   message: 111,
            // });
            // this.$toast(res.errmsg + 19);
            sessionStorage.appleUserId = res.data.appleUserId;
            setTimeout(this.goTowx, 2000);
          } else {
            this.$toast({
              message: "登录成功",
              duration: 1500,
              onClose: () => {
                //console.log(res);
                setToken(res.data.accessToken);
                // 把token存入本地
                localStorage.setItem("token", res.data.accessToken);
                localStorage.setItem("customerLevel", res.data.level);
                localStorage.setItem("customerId", res.data.id);
                localStorage.setItem(
                  "personalNumber",
                  res.data.birthday
                  // res.returnValue.loginCustomer.personalNumber
                );
                localStorage.setItem(
                  "customerName",
                  // res.returnValue.loginCustomer.name
                  res.data.nickname
                );
                localStorage.setItem(
                  "mobile",
                  // res.returnValue.loginCustomer.mobile
                  res.data.phone
                );
                localStorage.setItem("isNew", res.data.loginType);
                // res.returnValue.isNew);
                localStorage.setItem(
                  "isWomenFestival",
                  // res.returnValue.isWomenFestival
                  res.data.status
                );
                // 跳转页面
                try {
                  //console.log(this.$route.query);
                  if (this.$route.query.redirect) {
                    let url = decodeURIComponent(this.$route.query.redirect);
                    this.$router.replace({ path: url });
                  } else {
                    this.$router.replace({ path: "/my" });
                  }
                } catch (err) {
                  this.$router.replace({ path: "/my" });
                }
              },
            });
          }
        });
    },

    //微信code回传JS
    wxLoginCallback(params) {
      this.$toast.loading({
        duration: 5000, // 持续展示 toast
        forbidClick: true,
        // message: "登录中2" + JSON.stringify(paramsObj.code),
        message: "登录中",
      });

      //   this.$toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: this.deviceEnvi +'1',
      // });

      // this.$toast("安卓回调");
      let deviceEnvir = this.device();
      let paramsObj = "";
      if (this.deviceEnvir == "IOS") {
        paramsObj = JSON.parse(params);
      } else {
        paramsObj = params;
      }
      let arr = {
        code: paramsObj.code,
      };
      this.$toast.loading({
        duration: 50000, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
        // message: JSON.stringify(arr) + 99,
      });

      //   this.$toast.loading({
      //   duration: 0, // 持续展示 toast
      //   forbidClick: true,
      //   message: this.deviceEnvi +'2',
      // });

      this.$api.login.wxgo(arr).then((res) => {
        if (!res.data.accessToken) {
          this.$toast("您尚未绑定微信");
          // this.$toast(res.errmsg + 19);
          sessionStorage.appOpenId = res.data.appOpenId;
          setTimeout(this.goTowx, 2000);
        } else {
          this.$toast({
            message: "登录成功",
            duration: 1500,
            onClose: () => {
              //console.log(res);
              setToken(res.data.accessToken);
              // 把token存入本地
              localStorage.setItem("token", res.data.accessToken);
              localStorage.setItem("customerLevel", res.data.level);
              localStorage.setItem("customerId", res.data.id);
              localStorage.setItem(
                "personalNumber",
                res.data.birthday
                // res.returnValue.loginCustomer.personalNumber
              );
              localStorage.setItem(
                "customerName",
                // res.returnValue.loginCustomer.name
                res.data.nickname
              );
              localStorage.setItem(
                "mobile",
                // res.returnValue.loginCustomer.mobile
                res.data.phone
              );
              localStorage.setItem("isNew", res.data.loginType);
              // res.returnValue.isNew);
              localStorage.setItem(
                "isWomenFestival",
                // res.returnValue.isWomenFestival
                res.data.status
              );
              // 跳转页面
              try {
                //console.log(this.$route.query);
                if (this.$route.query.redirect) {
                  let url = decodeURIComponent(this.$route.query.redirect);
                  this.$router.replace({ path: url });
                } else {
                  this.$router.replace({ path: "/my" });
                }
              } catch (err) {
                this.$router.replace({ path: "/my" });
              }
            },
          });
        }
      });
    },

    device() {
      var u = window.navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      var isWeiChat = u.match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
      if (isAndroid === true) {
        // 安卓
        return "0";
      } else if (isIOS === true) {
        // IOS
        return "1";
      }
    },

    checkWechat() {
      switch (this.deviceEnvir) {
        case "IOS":
          try {
            window.webkit.messageHandlers.checkWechat.postMessage("0");
          } catch (error) {}
          break;
      }
    },

    checkWechatCallback(params) {
      let paramsObj = JSON.parse(params);

      if (paramsObj == "1") {
        this.isHaveWechat = true;
      } else if (paramsObj == "0") {
        this.isHaveWechat = false;
      }
    },
  },
};
</script>






<style lang="less" scoped>
.login {
  width: 100vw;
  min-height: 100vh;
  background: #fff;
  // background: var(--live-body-background);
  .title {
    width: 90%;
    height: 4.8rem /* 213/50 */;
    padding-left: 5%;
    // background: url("../../assets/images/login-title.png") no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      margin-top: .6rem ;
      width: 66% /* 76/50 */;
      height: 100%;

      // height: 1.12rem /* 56/50 */;
      // margin: 1.04rem /* 37/50 */ 0 0.32rem /* 16/50 */;
    }
    > .text {
      font-size: 0.4rem /* 20/50 */;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .info {
    margin-top: 0.4rem /* 20/50 */;
    padding: 0 0.6rem /* 30/50 */;
    > .txt {
      margin-bottom: 0.28rem /* 14/50 */;
      font-size: 0.32rem /* 16/50 */;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .login-box-input {
      box-sizing: border-box;
      width: 6.3rem /* 315/50 */;
      height: 0.9rem /* 45/50 */;
      background: #f3f3f3 !important;
      border: 1px solid #efefef;
      border-radius: 5px;
      font-size: 0.32rem /* 16/50 */;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      padding: 0 0.32rem /* 16/50 */;
      display: flex;
      align-items: center;
      .login-input {
        width: 100%;
        background: #f3f3f3;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #aeaeae;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #aeaeae;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #aeaeae;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #aeaeae;
      }
    }
    .yanzheng-box {
      width: 3.94rem /* 197/50 */;
    }
  }
  .yanzheng-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.32rem /* 16/50 */;
  }
  .getCode {
    width: 2.08rem /* 104/50 */;
    height: 0.9rem /* 45/50 */;
    // background: #f3f3f3;
    // background: #EF5C62 !important;
    // background: linear-gradient(0deg, #EF5C62, #EF5C62) !important;
    // border: 1px solid @login_btn_color !important; 
    background: @login_btn_color;
    border: 1px solid #efefef !important;
    border-radius: 0.1rem;
    // border: 1px solid #efefef;
    // border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem /* 16/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    // color: #aeaeae;

    color: #ffffff !important;
  }
  .time {
    width: 2.08rem /* 104/50 */;
    height: 0.9rem /* 45/50 */;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid @login_btn_color;
    border: 1px solid #efefef;
    border-radius: 5px;
    box-sizing: border-box;
    background: #fff;
    font-style: normal;
  }

  /deep/ .van-button--normal {
    padding: 0;
  }
  .btn {
    margin: 0.6rem /* 30/50 */ 0.6rem /* 30/50 */ 0.3rem /* 15/50 */;
    width: 6.3rem /* 315/50 */;
    height: 0.96rem /* 48/50 */;
    // background: linear-gradient(0deg, #EF5C62, #EF5C62);
    background: @login_btn_color;
    border-radius: 5px;
    font-size: 0.36rem /* 18/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .extra {
    margin: 0 0.6rem /* 30/50 */ 0.4rem /* 20/50 */;
    width: 6.3rem /* 315/50 */;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    // color: #4273f8;
    .unable {
      color: #aeaeae;
    }
  }
  .more-text {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .more-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.32rem;
    .apple {
      width: 0.42rem /* 21/50 */;
      height: 0.5rem /* 25/50 */;
      margin-right: 2.08rem /* 104/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .weixin {
      width: 1rem /* 28/50 */;
      height: 1rem /* 22/50 */;
      margin-bottom: 0.2rem /* 20/50 */;
    }
  }
}
.backbox {
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.12rem /* 6/50 */ 0.32rem /* 16/50 */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem /* 14/50 */;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 999;
  .back {
    top: 0.12rem /* 6/50 */;
    left: 0.32rem /* 16/50 */;
    color: #fff;
    width: 0.64rem /* 32/50 */;
    text-align: center;
    height: 0.64rem /* 32/50 */;
    line-height: 0.64rem /* 32/50 */;
    border-radius: 50%;
    // background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  i {
    opacity: 0;
  }
  &.backFixed {
    background-color: #fff;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    .back {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      background-color: #fff;
      color: #ccc;
    }
    i {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      opacity: 1;
      color: #c83f3fb8;
    }
  }
}
.sign {
  margin: 0 /* 10/50 */ 0 0 0.6rem /* 30/50 */;
  display: flex;
  align-items: center;
  font-size: 0.24rem /* 12/50 */;
  // a {
  //   color: rgb(140, 187, 250);
  // }
  .agree {
    // height: .18rem /* 9/50 */;
    margin-left: 5px;
    color: #7e7e7e;
  }
}
label {
  text-align: center;
  display: block;
  font-size: 0.24rem /* 12/50 */;
  // width: 100%;
}
.isthree {
  position: fixed;
  left: 50%;
  bottom: 0.22rem;
  padding: 1.7rem 0 .5rem;
  transform: translateX(-50%);
  // padding: 0.5rem 0;
  // padding-top: 2rem;
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #aeaeae;
}
.isthreeLogin {
  display: flex;
  // justify-content: flex-start;
  justify-content: space-around;
  padding-top: 0.58rem;
}
.btn-no {
  background: #aeaeae !important;
}

/deep/ .van-checkbox__icon--round .van-icon {
  margin-top: 0.06rem;
  width: 0.26rem;
  height: 0.26rem;
}

/deep/ .van-icon-success::before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.24rem;
  height: 0.26rem;
}
.shop-name {
  margin-top: -1.6rem;
  font-size: 0.5rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.52rem;
}
/deep/ .checkbox-big {
  > .van-checkbox__icon .van-icon {
    width: 0.3rem;
    height: 0.3rem;
  }
}
</style>


