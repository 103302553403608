<template>
  <Heador :options="options" class="loginheader" />
</template>

<script>
import Heador from '@/components/public/header';
export default {
  data() {
    return {
      options: {
        leftcname: 'icon-fanhui-copy'
      }
    };
  },
  components: {
    Heador
  }
};
</script>

<style lang='less' scoed>
.loginheader {
  position: absolute;
  top: .24rem /* 12/50 */;
  z-index: 99;
  width: 100%;
  .head-left {
    color: #cfc9c9;
  }
}
</style>
